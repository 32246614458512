import React, { Component } from 'react'
import { withRouter } from 'react-router'
import 'rc-calendar/assets/index.css'
import ReactCalendar from 'rc-calendar/lib'
import { Modal } from 'react-bootstrap'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FixedSideBar from './FixedSideBar.jsx'
import BackButton from './BackButton.jsx'
import NextButton from './NextButton.jsx'
import CompanyContactFooter from './CompanyContactFooter.jsx'
import * as CalendarActions from '../Actions/Calendar.jsx'
import * as OrderActions from '../Actions/Order.jsx'
import { setNavCalendar, setNavLoading } from '../Actions/Nav.jsx'
import Container from './Container.jsx'
import Utils from '../../Common/Utils.jsx'
import { canSetupOnPageLoad, canSetupOnExistingState, getAnalyticsEventLabel} from '../util.jsx';
import { PopUpModal } from './PopUpModal.jsx'

/**
 *
 */
export class AppointmentList extends Component {
    constructor(props) {
        super(props);

        this.selectAppointment = this.selectAppointment.bind(this);
        this.updateIndex = this.updateIndex.bind(this)

        this.state = {
            selected: null,
            saving: false,
            currentVal: this.props.currentTempIndex
        };
    }
    updateIndex = (i, data) => {
        this.props.selectTempIndex(i, data)
    }
    

    selectAppointment(index) {
        
        let appointment = this.props.appointments[index];
        if (appointment.hasOwnProperty('group')) {
            // pick randomly
            appointment = appointment.group[Math.floor(Math.random()*appointment.group.length)];
        }
        this.setState({
            selected: appointment,
            saving: true
        });
        this.props.selectAppointment(appointment);
    }
    componentDidMount(){
        // this.props.setload(true)
        // console.log("appt slots", this.props)
        this.props.appointments.map((appointment, index) => {
            let inspector = this.props.users.find((obj) => {
                return obj.id === appointment.users[0];
            });
            if (inspector) {
                if(this.props.selected_appointment){
                    const selectedAppt = this.props.selected_appointment
                    if(selectedAppt.id == appointment.id){
                        this.updateIndex(index, this.props.appointments)
                        // console.log("currentappt", index)
                    }
                }
            }
        })
        // this.props.setload(false)
        
    }

    render() {
        const shortView = this.props.hasOwnProperty('shortView') && this.props.shortView;
        const showInspectorNames = this.props.hasOwnProperty('showInspectorNames') && this.props.showInspectorNames;

        this.props.appointments.sort((a,b) => {
            if ('preferred' in a && 'preferred' in b) {
                if (a.preferred > b.preferred) {
                    return 1;
                }
                if (a.preferred < b.preferred) {
                    return -1;
                }
            } else if ('preferred' in a && !('preferred' in b)) {
                return -1;
            } else if (!('preferred' in a) && 'preferred' in b) {
                return 1;
            }
            if (a.timestamp > b.timestamp) {
                return 1;
            }
            if (a.timestamp < b.timestamp) {
                return -1;
            }
            return 0;

        });
        return (
            <div className={this.props.appointments.length > 4 ? 'setHeightAppt' : ''} style={{overflowY:"auto"
        }}>
            {/* {console.log("appointments", this.props)} */}
                {this.props.appointments.map((appointment, index) => {
                    let inspectorNames = [];
                    for (let i = 0, l = appointment.users.length; i < l; i++) {
                        let inspector = this.props.users.find((obj) => {
                            return obj.id === appointment.users[i];
                        });
                        if (inspector) {
                            if ('preferred' in appointment) {
                                inspectorNames.push(inspector.display + ' *');
                            } else {
                                inspectorNames.push(inspector.display);
                            }
                        }
                    }
                    return (
                        <a key={index} href={'#!'} className={index == this.props.currentTempIndex ? 'list-group-item active-option' : 'list-group-item'}
                        id={index}
                           style={{fontSize:'90%',position:'relative', marginBottom: "8px"}}
                           onClick={(event) => { this.updateIndex(index, this.props.appointments);
                        
                           }}>
                            {shortView && <span  style={{fontWeight:"600"}}>{appointment.start}</span>}
                            {!shortView && <span >
                                {moment(appointment.start_datetime).format('dddd, MMMM Do YYYY')} at {appointment.start}
                            </span>}
                            {showInspectorNames && <div > 
                                with {Utils.commaList(inspectorNames)}
                                {/* {console.log("inspectornames", this.props.currentTempIndex)} */}
                            </div>}
                        </a>
                    );
                })}
            </div>
        );
    }
}


export class PackageAppointmentList extends Component {
    constructor(props) {
        super(props);

        this.selectAppointment = this.selectAppointment.bind(this);

        this.state = {
            selected: null,
            saving: false
        };
    }

    selectAppointment(index) {
        let appointment = this.props.appointments[index];
        if (appointment.hasOwnProperty('group')) {
            // pick randomly
            appointment = appointment.group[Math.floor(Math.random()*appointment.group.length)];
        }
        this.setState({
            selected: appointment,
            saving: true
        });
        this.props.selectAppointment(appointment);
    }

    render() {

        const shortView = this.props.hasOwnProperty('shortView') && this.props.shortView;
        const showInspectorNames = this.props.hasOwnProperty('showInspectorNames') && this.props.showInspectorNames;

        this.props.appointments.sort((a,b) => {
            if (a.timestamp > b.timestamp) {
                return 1;
            }
            if (a.timestamp < b.timestamp) {
                return -1;
            }
            return 0;
        });

        return (
            <div className={'list-group'}>
                {this.props.appointments.map((appointment, index) => {
                    let inspectorNames = [];
                    for (let i = 0, l = appointment.users.length; i < l; i++) {
                        let inspector = this.props.users.find((obj) => {
                            return obj.id === appointment.users[i];
                        });
                        if (inspector) {
                            inspectorNames.push(inspector.display);
                        }
                    }
                    return (
                        <a key={index} href={'#!'} className={'list-group-item'}
                           style={{fontSize:'90%',position:'relative'}}
                           onClick={() => { this.selectAppointment(index); }}>
                            {shortView && <span>{appointment.start}</span>}
                            {!shortView && <span>
                                {moment(appointment.start_datetime).format('dddd, MMMM Do YYYY')} at {appointment.start}
                            </span>}
                            {showInspectorNames && <div>
                                with {Utils.commaList(inspectorNames)}
                            </div>}
                        </a>
                    );
                })}
            </div>
        );
    }
}


export class AppointmentAllList extends Component {
    constructor(props) {
        super(props);

        this.selectAppointment = this.selectAppointment.bind(this);
        this.updateIndex = this.updateIndex.bind(this);

        this.state = {
            selected: null,
            saving: false,
            currentVal: this.props.currentTempIndex
        };
    }
    updateIndex = (i, data) => {
        this.props.selectTempIndex(i, data)
    }
    

    selectAppointment(index) {
        
        let appointment = this.props.appointments[index];
        if (appointment.hasOwnProperty('group')) {
            // pick randomly
            appointment = appointment.group[Math.floor(Math.random()*appointment.group.length)];
        }
        this.setState({
            selected: appointment,
            saving: true
        });
        this.props.selectAppointment(appointment);
    }
    componentDidMount(){
        this.props.setload(true)
        // console.log("appt slots", this.props)
        this.props.appointments.map((appointment, index) => {
            let inspector = this.props.users.find((obj) => {
                return obj.id === appointment.users[0];
            });
            if (inspector) {
                if(this.props.selected_appointment){
                    const selectedAppt = this.props.selected_appointment
                    if(selectedAppt.id == appointment.id){
                        this.updateIndex(index, this.props.appointments)
                        // console.log("currentappt", index)
                    }
                }
            }
        })
        this.props.setload(false)
        
    }

    render() {
        const shortView = this.props.hasOwnProperty('shortView') && this.props.shortView;
        const showInspectorNames = this.props.hasOwnProperty('showInspectorNames') && this.props.showInspectorNames;

        this.props.appointments.sort((a,b) => {
            if (a.timestamp > b.timestamp) {
                return 1;
            }
            if (a.timestamp < b.timestamp) {
                return -1;
            }
            return 0;

        });
        return (
            <div ref={this.props.scrollableContainerRef} >
            {/* {console.log("appointments", this.props)} */}
                {this.props.appointments.map((appointment, index) => {
                    let inspectorNames = [];
                    for (let i = 0, l = appointment.users.length; i < l; i++) {
                        let inspector = this.props.users.find((obj) => {
                            return obj.id === appointment.users[i];
                        });
                        if (inspector) {
                            inspectorNames.push(inspector.display);
                        }
                    }
                    return (
                        <a key={index} href={'#!'} className={index == this.props.currentTempIndex ? 'list-group-item active-option' : 'list-group-item'}
                        id={index}
                           style={{fontSize:'90%',position:'relative', marginBottom: "8px"}}
                           onClick={(event) => { this.updateIndex(index, this.props.appointments);
                        
                           }}>
                            {shortView && <span  style={{fontWeight:"600"}}>{appointment.start}</span>}
                            {!shortView && <span >
                                {moment(appointment.start_datetime).format('dddd, MMMM Do YYYY')} at {appointment.start}
                            </span>}
                            {showInspectorNames && <div > 
                                with {Utils.commaList(inspectorNames)}
                                {/* {console.log("inspectornames", this.props.currentTempIndex)} */}
                            </div>}
                        </a>
                    );
                })}
            </div>
        );
    }
}

/**
 *
 */
class OnlineSchedulerCalendarView extends Component {
    constructor(props) {
        super(props);

        this.isDateDisabled = this.isDateDisabled.bind(this);
     
    }

    isDateDisabled(date) {
        return (!date
            || date < this.props.now
            || (date.format('M-D-YYYY') === this.props.now.format('M-D-YYYY') && !this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_ALLOW_TODAY'))
            || this.props.availability.hasOwnProperty(date.format('YYYY-MM-DD')) && this.props.availability[date.format('YYYY-MM-DD')].length === 0
        );
    }




    render() {

        let appointmentLength = 0;
        for (let i in this.props.availability) {
            if (this.props.availability.hasOwnProperty(i)) {
                appointmentLength += this.props.availability[i].length;
            }
        }

        return (<div style={{position:'relative'}}>
            {this.props.loading && <div className="text-center">
                <i className={'fas fa-spinner-third fa-spin fa-2x'}/>
                <div style={{fontStyle:'italic'}}>Please wait</div>
            </div>}
            {!this.props.loading && this.props.start
                && <div>
                    {this.props.loading_more && <div className="calendar-disabled">
                        <i className="fa fa-spin fa-spinner-third fa-2x"
                            style={{position:'absolute',left:'45%',top:'45%'}}/>
                    </div>}
                    {appointmentLength === 0 && <div className={'alert alert-warning alert-tight'} style={{marginBottom:'20px', fontWeight: "600",
    fontSize: "14px",
    borderRadius: '4px'}}>
                        <p>We're sorry. There are no appointments available. Please call
                            our office at <a href={"tel:"+this.props.office_phone}>{this.props.office_phone}</a> for
                            help scheduling your inspection.</p>
                    </div>}
                    {appointmentLength > 0 && <div>
                        <div style={{fontSize:"20px", color:"#757575", marginLeft: "103px", fontWeight: "600", position: "relative", top: "16px"}} >Select a day</div>
                        <ReactCalendar
                            defaultValue={this.props.selected_date}
                            mode={this.props.mode}
                            className="osv3-calendar"
                            showDateInput={false}
                            showToday={this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_ALLOW_TODAY')}
                            disabledDate={this.isDateDisabled}
                            onSelect={this.props.onCalendarDateSelect}
                            onChange={this.props.onCalendarDateChange}/></div>}
                </div>
            }
        </div>);
    }
}

/**
 *
 */
class OnlineSchedulerAppointmentListView extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount(){
        this.props.initListAvailability()
    }
    
    render() {
      
        return <div>
            {this.props.loading && !this.props.loading_more && <div className="text-center">
                    <i className={'fas fa-spinner-third fa-spin fa-2x'}/>
                    <div style={{fontStyle:'italic'}}>Please wait</div>
                </div>}
            <AppointmentAllList shortView={false}
                             services={this.props.order.services}
                             showInspectorNames={this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_SHOW_INSPECTOR_NAMES')}
                             appointments={this.props.appointments}
                             selectAppointment={this.props.selectAppointment}
                             users={this.props.users}
                             selectTempIndex={this.props.selectTempIndex}
                             currentTempIndex={this.props.currentTempIndex}
                             selected_appointment={this.props.selected_appointment}
                             setload={this.props.setload}
                                    />
            {this.props.loading_more && <div className="text-center">
                <i className={'fas fa-spinner-third fa-spin fa-2x'}/>
                <div style={{fontStyle:'italic'}}>We're loading more appointments</div>
            </div>}
            <div id={'list-availability-bottom'}>
                {!this.props.stop && !this.props.loading && !this.props.loading_more && this.props.appointments.length > 0
                && <button type="button" className="btn btn-info btn-block"
                        id="btn-load-more-availability"
                        disabled={this.props.loading_more}
                        onClick={this.props.getMoreAvailability}>
                    Show More <i className="fa fa-chevron-down"/>
                </button>}
                {!this.props.loading && !this.props.loading_more && this.props.stop && <div className={'alert alert-warning alert-tight'}>
                    <p>We're sorry. There are no {this.props.appointments.length ? 'additional' : ''} appointments available. If you need a date not listed please call
                        our office at <a href={"tel:"+this.props.office_phone}>{this.props.office_phone}</a> for
                        help scheduling your inspection.</p>
                </div>}
            </div>
        </div>
    }
}

/**
 *
 */
// const OnlineSchedulerAppointmentAlreadySelectedView = (props) => (
//     <div className="text-center">
//         <a href="javascript:;" onClick={props.reset} className="btn calendar-btn">
//           <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 -960 960 960"width="20" fill="#0B5284">
//             <path d="M180-80q-24 0-42-18t-18-42v-620q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H180Zm0-60h600v-430H180v430Zm0-490h600v-130H180v130Zm0 0v-130 130Zm300 230q-17 0-28.5-11.5T440-440q0-17 11.5-28.5T480-480q17 0 28.5 11.5T520-440q0 17-11.5 28.5T480-400Zm-160 0q-17 0-28.5-11.5T280-440q0-17 11.5-28.5T320-480q17 0 28.5 11.5T360-440q0 17-11.5 28.5T320-400Zm320 0q-17 0-28.5-11.5T600-440q0-17 11.5-28.5T640-480q17 0 28.5 11.5T680-440q0 17-11.5 28.5T640-400ZM480-240q-17 0-28.5-11.5T440-280q0-17 11.5-28.5T480-320q17 0 28.5 11.5T520-280q0 17-11.5 28.5T480-240Zm-160 0q-17 0-28.5-11.5T280-280q0-17 11.5-28.5T320-320q17 0 28.5 11.5T360-280q0 17-11.5 28.5T320-240Zm320 0q-17 0-28.5-11.5T600-280q0-17 11.5-28.5T640-320q17 0 28.5 11.5T680-280q0 17-11.5 28.5T640-240Z" />
//           </svg>&nbsp;{" "}
//           <span style={{color:"#0B5284", fontStyle: "normal",fontWeight: "600", fontSize: "14px"}}>Change</span>
//         </a>
//         <div style={{marginTop:'50px'}}>
//             <div className={"col-md-12"+ (props.calendarLast !== false  ? ' btn-show' : ' btn-centered')}>
//                 {props.calendarLast && <BackButton className="back-btn-class" back={props.prev}/>}
//                 <NextButton style={{position:"static"}} className={'next-btn-class'+(props.calendarLast !== false  ? '' : '')} next={props.next} />
//             </div>
//         </div>
//     </div>
// );

/**
 *
 */
export class OnlineSchedulerAppointmentNotAvailableModalView extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Modal show={true} onHide={this.props.close} bsSize="lg" className="appointment-na-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    Sorry!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '5px 10px' }}>
                <p>The appointment on <strong>{moment(this.props.datetime).format('M/D/YYYY [at] h:mmA')}</strong> is no longer available.</p>
                {this.props.appointments.length > 0 && <div>
                    <p>Please select another appointment:</p>
                    <AppointmentList shortView={false}
                                     services={this.props.order.services}
                                     showInspectorNames={this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_SHOW_INSPECTOR_NAMES')}
                                     appointments={this.props.appointments}
                                     selectAppointment={this.props.selectAppointment}
                                     users={this.props.users}
                                     />
                </div>}
                {this.props.appointments.length === 0 && <div>
                    <p>We're sorry. There are no other appointments available for your service requirements.
                        Please call our office at <a href={"tel:"+this.props.office_phone}>{this.props.office_phone}</a> for help scheduling your inspection.</p>
                </div>}
            </Modal.Body>
            <Modal.Footer>
                <div className="pull-right">
                    <div className="btn-toolbar">
                        <button type="button" className="btn btn-default"
                                onClick={this.props.close}>Close</button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>;
    }
}

/**
 *
 */
class OnlineSchedulerAvailabilityAppointmentModalView extends Component {
    constructor(props) {
        super(props);
    }

    
    render() {
        const {selectTempIndex} = this.props;
        const showInspectorNames = this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_SHOW_INSPECTOR_NAMES');
        let appointments = this.props.availability[this.props.selected_date.format('YYYY-MM-DD')];
        if (!showInspectorNames) {
            appointments = CalendarContainer.groupAppointments(appointments);
        }

        if (this.props.limit > 0 && appointments) {
            appointments = appointments.slice(0, this.props.limit);
        }


        // console.log("modal view 2", this.props)
        return <div show={true} onHide={this.props.close} bsSize="lg" className="appointment-modal" style={{position: "relative",
            width: "320px",
            top: "17px",
            }}>
                {this.props.selected_date
                && this.props.availability.hasOwnProperty(this.props.selected_date.format('YYYY-MM-DD'))
                && 
                <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <div style={{fontSize:"20px", color:"#757575", fontWeight: "600"}}>Select a time slot</div>
            <div style={{fontSize:"10px", marginbottom:"5px"}}>* indicates preferred inspectors</div>
            <div className='slots-wrapper'>
                <div style={{padding: '5px'}}>
                                    <AppointmentList shortView={true}
                                    order={this.props.order}
                                    services={this.props.order.services}
                                    showInspectorNames={showInspectorNames}
                                    appointments={appointments}
                                    selectAppointment={this.props.selectAppointment}
                                    users={this.props.users}
                                    selectTempIndex={selectTempIndex}
                                    currentTempIndex={this.props.currentTempIndex}
                                    selected_appointment={this.props.selected_appointment}
                                    setload={this.props.setload}
                                    />
                
                </div>
            </div>
            </div>
            }
    </div>;



        // return <Modal show={true} onHide={this.props.close} bsSize="lg" className="appointment-modal">
        //     <Modal.Header closeButton>
        //         <Modal.Title>
        //             <div className="hidden-xs hidden-sm">
        //                 Availability on {this.props.selected_date ? this.props.selected_date.format('dddd, MMMM Do YYYY') : 'n/a'}
        //             </div>
        //             <div className="hidden-md hidden-lg">
        //                 Availability on {this.props.selected_date ? this.props.selected_date.format('M/D/YYYY') : 'n/a'}
        //             </div>
        //         </Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body style={{ padding: '5px 10px' }}>
        //         <div style={{padding: '25px'}}>
        //             {this.props.selected_date
        //             && this.props.availability.hasOwnProperty(this.props.selected_date.format('YYYY-MM-DD'))
        //             && <AppointmentList shortView={true}
        //                                 services={this.props.order.services}
        //                                 showInspectorNames={showInspectorNames}
        //                                 appointments={appointments}
        //                                 selectAppointment={this.props.selectAppointment}
        //                                 users={this.props.users}/>
        //             }
        //         </div>
        //     </Modal.Body>
        //     <Modal.Footer>
        //         <div className="pull-right">
        //             <div className="btn-toolbar">
        //                 <button type="button" className="btn btn-default"
        //                         onClick={this.props.close}>Close</button>
        //             </div>
        //         </div>
        //     </Modal.Footer>
        // </Modal>;
    }
}

/**
 *
 */
export class CalendarContainer extends Component {
    constructor(props) {
        super(props);

        this.selectAppointment = this.selectAppointment.bind(this);
        this.onCalendarDateSelect = this.onCalendarDateSelect.bind(this);
        this.onCalendarDateChange = this.onCalendarDateChange.bind(this);
        this.getAvailability = this.getAvailability.bind(this);
        this.getMoreAvailability = this.getMoreAvailability.bind(this);
        this.showAppointmentModal = this.showAppointmentModal.bind(this);
        this.closeAppointmentModal = this.closeAppointmentModal.bind(this);
        this.reset = this.reset.bind(this);
        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.initListAvailability = this.initListAvailability.bind(this);
        this.initCalendarAvailability = this.initCalendarAvailability.bind(this);
        this.setup = this.setup.bind(this);
        this.getDateTimeDurationSessionStoreKey = this.getDateTimeDurationSessionStoreKey.bind(this);
        this.selectTempIndex = this.selectTempIndex.bind(this)
        this.appointmentSelected = this.appointmentSelected.bind(this)
        this.getFirstNonEmptyKey = this.getFirstNonEmptyKey.bind(this)
        this.setload = this.setload.bind(this)
        this.state = {
            saving: false,
            no_availability: false,
            loading: true,
            loading_more: false,
            modal: {
                appointment: {
                    show: false
                }
            },
            appointments: [],
            appointment_check_count: 0,
            appointment_check_stop: false,
            start: null,
            offset: 0,
            currentTempIndex: null,
            showModal: false
        };
    }
    selectTempIndex = (i, data) => {
        this.setState({
            currentTempIndex: i,
            appointments: [...data]
        })


    }

    getDateTimeDurationSessionStoreKey() {
        return 'os-datetime-' + this.props.token;
    }

    onCalendarDateSelect(date) {
        const { setSelectedDate }
            = this.props.calendarActions;
        setSelectedDate(date);
        this.showAppointmentModal();
    }

    onCalendarDateChange(date) {
        this.setState({currentTempIndex: null})
        this.setState({loading_more:true});
        this.props.calendarActions.onCalendarDateChange(date)
            .then(() => {
                this.setState({loading_more:false})
                })
    }
    appointmentSelected(index){
        let appointment = this.state.appointments[index]
        if (appointment?.hasOwnProperty('group')) {
            // pick randomly
            appointment = appointment.group[Math.floor(Math.random()*appointment.group.length)];
        }
        this.selectAppointment(appointment)
    }

    selectAppointment(appointment) {
        this.setState({saving:true});

        const { setSelectedAppointment, save }
            = this.props.calendarActions;
        const { setDatetime, setDuration, setInspectors }
            = this.props.orderActions;
        const from = moment(appointment.start_datetime);
        const to = moment(appointment.end_datetime);
        const duration = Math.abs(to.diff(from, 'minutes'));

        this.props.setNavLoading(true);
        setSelectedAppointment(appointment);

        if (this.props.order.services && this.props.order.services.length > 1) {
            // we have multiple services and need all inspectors provided for them
            setInspectors(appointment.users.map((inspector) => {
                return {id: inspector, requested: false}
            }));
        } else {
            // we have 0 or 1 services and can pick a random inspector from the timeslot
            setInspectors([{
                id: appointment.users[Math.floor(Math.random()*appointment.users.length)],
                requested: false
            }]);
        }

        setDatetime(from);

        if (parseInt(this.props.order.duration) <= 0) {
            setDuration(duration);
        }

        if (this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST') || this.props.order.saved) {
            save().then(this.next);
        } else {
            sessionStorage.setItem(this.getDateTimeDurationSessionStoreKey(), appointment.start_datetime + ',' + duration);
            this.next();
        }

        if (window.ga) {
            window.ga('send', {
                hitType: 'event',
                eventCategory: 'Online Scheduler',
                eventAction: 'selected-appointment',
                eventLabel: getAnalyticsEventLabel(this.props.company_key, 'Appointment was selected')
            });
        }
    }
    setload(bool){
        this.setState({loading: bool})
    }

    reset() {
        const { setDatetime, setDuration, setInspectors }
            = this.props.orderActions;
        this.setState({loading:true});
        setDatetime(null);
        setInspectors([]);

        if (this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_APT_DISPLAY') === 'list') {
            this.initListAvailability();

        } else {
            this.initCalendarAvailability();
            this.setState({loading:false});
        }
    }

    prev() {
        this.props.router.history.push('/'+this.props.company_key+'/online-scheduler/packages?t='+this.props.token + '&office=' + this.props.office_uuid);
    }

    next() {
        this.props.calendarActions.setServicesChanged(false);
        this.setState({saving:false});
        if (this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST')) {
            this.setState({showModal: true});
            // this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/review?t=' + this.props.token + '&office=' + this.props.office_uuid);
        } else {
            if (window.ga) {
                window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'Online Scheduler',
                    eventAction: 'started',
                    eventLabel: getAnalyticsEventLabel(this.props.company_key, 'New Online Scheduler Order started')
                });
            }
            this.props.router.history.push('/' + this.props.company_key + '/online-scheduler/address?t=' + this.props.token + '&office=' + this.props.office_uuid);
        }
    }

    showAppointmentModal() {
        let modal = this.state.modal;
        modal.appointment.show = true;
        this.setState({modal});
    }

    closeAppointmentModal() {
        let modal = this.state.modal;
        modal.appointment.show = false;
        this.props.calendarActions.setSelectedDate(null);
        this.setState({modal});
    }

    static groupAppointments(appointments) {
        let _appointments = [];
        for (let i = 0, l = appointments.length; i < l; i++) {
            appointments[i].group = [appointments[i]];
            for (let x = i + 1, y = appointments.length; x < y; x++) {
                if (appointments[i].date === appointments[x].date && appointments[i].start === appointments[x].start) {
                    appointments[i].group.push(appointments[x]);
                }
            }
            if (!_appointments.find((apt) => {
                    return apt.date === appointments[i].date && apt.start === appointments[i].start
                })) {
                _appointments.push(appointments[i]);
            }
        }
        return _appointments;
    }

    static getAppointmentsFromAvailability(availability) {
        let appointments = [];
        Object.keys(availability).map((date) => {
            availability[date].map((appointment) => {
                appointments.push(appointment);
            });
        });
        return appointments;
    }

    getAvailability(from, to) {
        return this.props.calendarActions.getAvailability(from,
            to,
            0,
            this.props.order.duration,
            [],
            this.props.order.services,
            this.props.order.postal_code
        ).then((availability) => {
            if (false === availability) {
                this.setState({no_availability: true});
            }
            return availability;
        }).catch(() => {});
    }

    initListAvailability() {
        const weeksAhead = 6;
        let from = moment();
        if (!this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_ALLOW_TODAY')) {
            from.add(1, 'days');
        }
        let to = from.clone();
        to.add(weeksAhead, 'weeks');
        this.setState({loading:true}), 
            // () => {
            this.getAvailability(from, to)
                .then((availability) => {
                    // handle the visible slice of availability based on settings
                    const limit = parseInt(this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_APPOINTMENT_LIMIT')
                        ? this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_APPOINTMENT_LIMIT_VALUE') || 10
                        : 0);
                    const showNames = this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_SHOW_INSPECTOR_NAMES');

                    let appointments = CalendarContainer.getAppointmentsFromAvailability(availability);

                    if (!showNames) {
                        appointments = CalendarContainer.groupAppointments(appointments);
                    }

                    if (limit > 0) {
                        appointments = appointments.slice(this.state.offset, limit);
                    }

                    this.setState({
                        appointments,
                        loading: false
                    });
                    this.props.setNavLoading(false);

                });
        // };
    }

    initCalendarAvailability() {
        let from = moment();
        if (!this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_ALLOW_TODAY')) {
            from.add(1, 'days');
        }
        let to = from.clone();
        to.add(6, 'weeks');
        this.setState({loading:true});
        this.getAvailability(from, to).then(() => {
            // check availability for the remainder of this month
            let date = new moment();
            let keys = Object.keys(this.props.availability);
            let bAvailThisMonth = false;
            let month = date.format('M');
            let i = 0;
            let l = keys.length;
            while (!bAvailThisMonth && i < l) {
                let d = new moment(keys[i]);
                if (d.format('M') === month) {
                    bAvailThisMonth = this.props.availability[keys[i]].length > 0;
                }
                i++;
            }
            if (!bAvailThisMonth) {
                // move to the next month
                date = date.endOf('month');
                date.add(1, 'day');
            }
            this.setState({loading:false,start:date});
            this.props.setNavLoading(false);
        });
    }

    getMoreAvailability(bNoScroll = false) {
        this.setState({loading_more: true});
        const showNames = this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_SHOW_INSPECTOR_NAMES');

        const fetch = (from, to) => {
            return this.getAvailability(from,
                to,
                0,
                this.props.order.duration,
                [],
                this.props.order.services,
                this.props.order.postal_code
            ).then((availability) => {
                let appointments = CalendarContainer.getAppointmentsFromAvailability(availability);
                if (!showNames) {
                    appointments = CalendarContainer.groupAppointments(appointments);
                }
                return appointments;
            });
        };

        const scrollToButton = () => {
            const btn = document.getElementById('list-availability-bottom');
            window.scrollTo(0, btn.offsetTop);
        };

        return new Promise((resolve, reject) => {

            let bStop = false;

            if (this.state.appointment_check_count >= 6) {
                bStop = true;
                this.setState({appointment_check_stop: true});
            }

            const limit = parseInt(this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_APPOINTMENT_LIMIT')
                ? this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_APPOINTMENT_LIMIT_VALUE') || 10
                : 0);
            let appointments = this.state.appointments;
            let _appointments = CalendarContainer.getAppointmentsFromAvailability(this.props.availability);

            if (!showNames) {
                _appointments = CalendarContainer.groupAppointments(_appointments);
            }

            let from = this.props.list_mode_last_dte instanceof moment ? this.props.list_mode_last_dte : moment();
            let to = from.clone();

            // if we're limiting, do we have N more to show?
            if (limit > 0) {
                let offset = this.state.offset + limit;
                let nextSlice = _appointments.splice(offset, limit);
                if (!bStop && _appointments.length < offset+limit) {
                    // we need to fetch more
                    to.add(4, 'weeks');

                    fetch(from, to)
                        .then(() => {
                            let appointment_check_count = this.state.appointment_check_count+1;
                            this.setState({appointment_check_count}, () => {
                                resolve();
                                this.getMoreAvailability(bNoScroll);
                            });
                        }
                    );

                } else {
                    // next slice is adequate, advance offset
                    appointments = appointments.concat(nextSlice);
                    this.setState({
                        appointments,
                        appointment_check_count: 0,
                        offset,
                        loading_more: false
                    }, () => {
                        resolve();
                        if (!bNoScroll) {
                            scrollToButton();
                        }
                    });
                }
            } else {
                to.add(4, 'weeks');

                fetch(from, to)
                    .then((_appointments) => {
                        appointments = appointments.concat(_appointments);
                        this.setState({
                            appointments,
                            loading_more: false
                        }, () => {
                            resolve();
                            if (!bNoScroll) {
                                scrollToButton();
                            }
                        });
                    }
                );
            }
        });
    }

    setup() {
        if (!this.props.order.datetime) {
                    if (this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_APT_DISPLAY') === 'list') {
                        this.initListAvailability();
                    } else {
                        this.initCalendarAvailability();
                    }
            // let dateFromSs = sessionStorage.getItem(this.getDateTimeDurationSessionStoreKey());
            // if (dateFromSs && dateFromSs !== '') {
            //     let parts = dateFromSs.split(',');
            //     this.props.orderActions.setDatetime(moment(parts[0]));
            //     this.props.orderActions.setDuration(parts[1]);
            // } else {
            //     if (this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_APT_DISPLAY') === 'list') {
            //         this.initListAvailability();
            //     } else {
            //         this.initCalendarAvailability();
            //     }
            // }
        }
        Utils.scrollTop();
        this.props.setNavCalendar(true);
        this.props.setNavLoading(false);
    }
    getFirstNonEmptyKey(obj){
        for (let key in obj) {
          if(obj[key].some(item => Object.keys(item).length > 0)){
            return key;
          }
        }
        return null; // Return null if no non-empty key is found
      }

    componentDidMount() {
        if (canSetupOnPageLoad(this.props)) {
            this.setup();
            // console.log("did mount", this.props, this.state)
        }
        this.setState({loading:true})
        
        if(this.props.selected_date){
            this.reset()
            this.onCalendarDateSelect(this.props.selected_date)
            this.showAppointmentModal()
            // this.setState({loading:false});
        }
        if(this.state.appointments.length == 0){
            let modal = this.state.modal;
            modal.appointment.show = false;
            this.setState({modal});
        }
        
    
      
      
       
    }
 

    componentWillReceiveProps(nextProps) {
        // run if we got here from a new state (i.e. new page load)
        if (canSetupOnExistingState(this.props, nextProps)) {
            this.setup();
        }
        if(this.props.selected_date){
            this.showAppointmentModal()
        }
    }

    toggleClose =() =>{
        this.setState({showModal: false})
    }

    render() {
        const limit = parseInt(this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_APPOINTMENT_LIMIT')
            ? this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_APPOINTMENT_LIMIT_VALUE') || 10
            : 0);
        const postalMode = this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_LIMIT_ZIP_BY_DAY')
            || this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_RESTRICT_INSPECTOR_ZIP');
        const containerClass = !this.props.order.saved && !postalMode ? '' : null;
        const bodyClass = '';

        const title = 
        // this.props.order.datetime && !this.state.saving
            // ? <span>Your inspection is tentatively set for<br/><strong>{this.props.order.datetime.format('M/D/YYYY [at] h:mmA')}</strong></span>
            // : 
            <span>When would you like your inspection?</span>;


        return (
            <div>
                {this.state.showModal && <PopUpModal {...this.props} toggleClose={this.toggleClose}/>}
                {!this.props.app.office_loading && !this.props.app.order_loading && <FixedSideBar {...this.props}/>}
                <Container classes={containerClass}
                           bodyClasses={bodyClass}
                           title={title}
                          
                           {...this.props}>
                    {/* {this.props.order.datetime
                        ? <OnlineSchedulerAppointmentAlreadySelectedView reset={this.reset}
                                                                         next={this.next}
                                                                         prev={this.prev}
                                                                         calendarLast={this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST')}/>
                        :  */}
                        <div style={{marginBottom:'25px'}}>
                            {this.state.no_availability
                                ?
                                <div className="alert alert-warning alert-tight c">
                                    <p>Sorry, we don't have any upcoming availability. Please contact us by
                                        calling <a href={'tel:'+this.props.office_phone}>{this.props.office_phone}</a> or
                                        emailing <a href={'mailto:'+this.props.office_email}>{this.props.office_email}</a>
                                    </p>
                                </div>
                                :
                                <div>
                                    {postalMode && <div>
                                        <div className={'div-cpc-res'}>
                                           <span className="calendar-text">Availability may be affected by the postal code you entered ({this.props.order.postal_code})</span> 

                                           <button type="button" className="btn btn-cpc-res fw-div" 
                                                    onClick={() => {
                                                        this.props.orderActions.setPostalCode('');
                                                        this.props.router.history.push('/'+this.props.company_key+'/online-scheduler?t='+this.props.token + '&office=' + this.props.office_uuid);
                                                    }}>
                                                <span style={{color: '#0B5284', fontWeight:'600', height: '10%'}}>Change postal code</span>
                                            </button>
                                            {/* <br /> */}
                                        </div>
                                    </div>}

                                    {this.props.settings.getSettingBool('ONLINE_SCHEDULER_V3_INSPECTOR_CALENDAR_LAST') && !this.state.saving && this.props.services_changed && <div className={'alert alert-warning alert-tight'} style={{marginBottom:'10px',fontWeight: "600",
    fontSize: "14px"}}>
                                        <i className={'fas fa-exclamation-triangle'}/>&nbsp;&nbsp;Your shopping cart has changed and requires you to select another appointment.
                                    </div>}

                                    {this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_APT_DISPLAY') === 'list'
                                        && <div className='all-appt-list-wrapper' ><OnlineSchedulerAppointmentListView {...this.props}
                                                                               appointments={this.state.appointments}
                                                                               loading={this.state.loading}
                                                                               loading_more={this.state.loading_more}
                                                                               initListAvailability={this.initListAvailability}
                                                                               getMoreAvailability={this.getMoreAvailability}
                                                                               selectAppointment={this.selectAppointment}
                                                                               stop={this.state.appointment_check_stop}
                                                                               users={this.props.users}
                                                                               selectTempIndex={this.selectTempIndex}
                                                                                currentTempIndex={this.state.currentTempIndex}
                                                                                setload={this.setload}
                                                                                /></div>}
                                    <div className='calender-wrapper' >
                                    {this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_APT_DISPLAY') !== 'list'
                                        && <OnlineSchedulerCalendarView {...this.props}
                                                                        start={this.props.selected_date || this.state.start}
                                                                        loading={this.state.loading}
                                                                        loading_more={this.state.loading_more}
                                                                        onCalendarDateChange={this.onCalendarDateChange}
                                                                        onCalendarDateSelect={this.onCalendarDateSelect} />}

                                    {this.state.modal.appointment.show && !this.state.loading && this.props.settings.getSettingValue('ONLINE_SCHEDULER_V3_APT_DISPLAY') !== 'list'
                                        && <>
                                        {/* {console.log("modal view props", this.props)}  */}
                                        <OnlineSchedulerAvailabilityAppointmentModalView {...this.props}
                                        limit={limit}
                                        order={this.props.order}
                                        close={this.closeAppointmentModal}
                                        selectAppointment={this.selectAppointment}
                                        users={this.props.users}
                                        selectTempIndex={this.selectTempIndex}
                                        currentTempIndex={this.state.currentTempIndex}
                                        setload={this.setload}
                                        /></>
                                        }

                                        </div>
                                </div>
                            }
                           
                        </div>
                    {/* } */}
                   
                {/* <div className='alert alert-info' style={{position:"relative", top:"10px", padding:"6px" }}><span style={{color:"#0B5284", fontWeight:"600"}}>If there are days and times not available online, please call our office to schedule your inspection. Office phone: <a href={"tel:"+this.props.office_phone}>{this.props.office_phone}</a></span></div> */}
                {!this.state.loading
                    &&  <div className={this.props.calendar_last ? "showBtn" : "backBtnDisabled"} >{this.props.calendar_last && <div>
                    <BackButton className="back-btn-class" back={this.prev}/>
                </div>}
                {/* {this.state?.currentTempIndex !== null && !this.state.loading_more && */}
                <button id="exception-element" type="button" className={(this.state.saving) ? "next-btn-class hide-button" : 'next-btn-class'} disabled={!(this.state?.currentTempIndex !== null)} onClick={(e) => { this.appointmentSelected(this.state.currentTempIndex); e.stopPropagation()}} >Next</button>
                <button className={this.state.saving ? "next-btn-class show-button" : "hide-button"}>&nbsp;<i className="fa fa-spinner fa-spin"></i>&nbsp;</button>
                
                 {/* } */}
                </div>}
                </Container>
                
            </div>
        );
    }
}

/**
 *
 */
export default withRouter(connect(
    (state, ownProps) => {
        return { router: ownProps, ...state.Calendar, ...state.Office, order: state.Order, nav: state.Nav, app: state.App }
    },
    (dispatch) => {
        return {
            calendarActions: bindActionCreators(CalendarActions, dispatch),
            orderActions: bindActionCreators(OrderActions, dispatch),
            setNavCalendar: (value) => dispatch(setNavCalendar(value)),
            setNavLoading: (value) => dispatch(setNavLoading(value))
        }
    }
)(CalendarContainer));